import { useState, CSSProperties } from "react";
import { joinMippi } from "../requests";
import { Link, ScrollRestoration } from "react-router-dom";

const cleanNumber = (phoneNumber) => {
  if (phoneNumber[0] === "0") return phoneNumber.slice(1);
  else return phoneNumber;
};

const projectLeads = [
  {
    name: "Professor Brainsworth",
    message:
      "Truly, without citizen scientists like yourself, professional scientists like myself would be stumbling in the dark, with no shoes or socks on, clattering into the furniture and irreparably damaging our shins.",
  },
  {
    name: "Addison Venture",
    message:
      "This XP has been totally dope so far. My thanks go out to one as rad as yourself helping us get our heads round these nutso eggs!",
  },
  {
    name: "Prudentia Croesus",
    message:
      " Great acts of industry oft start from small beginnings. We expect plentiful returns from your gracious act of unremunerated labour.",
  },
];

const LeadBubble = ({ lead, pictureLeft }) => {
  const [visible, setVisible] = useState(false);

  const orderStyle = { flexDirection: pictureLeft ? "row" : "row-reverse" };

  return (
    <div
      onClick={() => setVisible(!visible)}
      className="lead-bubble"
      style={orderStyle as CSSProperties}
    >
      <ScrollRestoration />
      <div>
        <p className="lead-picture">[picture of {lead.name}]</p>
        <p className="lead-name">{lead.name}</p>
      </div>
      {visible && <p>{lead.message}</p>}
    </div>
  );
};

export default function SignUp() {
  /*
  const [countryCode, setCountryCode] = useState("44");
  const [phoneNumber, setPhoneNumber] = useState("");
  */
  const [matrixUserId, setMatrixUserId] = useState("");
  const [joined, setJoined] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const join = async () => {
    const joinResponse = await joinMippi("matrix", matrixUserId);
    const joinResult = await joinResponse.json();

    if (joinResult.success) setJoined(true);
    else {
      setError(true);
      setErrorMessage(joinResult.message);
    }
  };

  return (
    <div id="app-container">
      <div id="signup">
        <h1>Joint Antarctic Expedition: Volunteer Researcher Registration</h1>
        <p>
          The project leads of the Joint Antarctic Expedition would like to
          personally thank you for your interest in the volunteer research
          programme. Click on them to read their thank you message.
        </p>
        {projectLeads.map((lead, index) => (
          <LeadBubble lead={lead} pictureLeft={index % 2 === 0} />
        ))}
        {joined ? (
          error ? (
            <p>
              {errorMessage} To get help with this error please report it via{" "}
              <Link to={"/report"}>the error form.</Link>
            </p>
          ) : (
            <p>
              Thank you for joining the JAE volunteer programme, our contact
              will be in touch with you shortly to transfer your research
              materials and provide your initial instructions.
            </p>
          )
        ) : (
          <>
            <p>
              Please enter your matrix user id below to join the volunteer
              programme.
            </p>
            <div id="phonenumber">
              {/*
            <select
              id="country-code-input"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="44">+44</option>
              <option value="33">+33</option>
              <option value="1">+1</option>
            </select>
            <input
              id="phone-number-input"
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="WhatsApp number"
            ></input>
            */}
              <input
                id="matrix-user-input"
                type="text"
                value={matrixUserId}
                onChange={(e) => setMatrixUserId(e.target.value)}
                placeholder="@matrixuser:matrixdomain"
              ></input>
            </div>
            {joined === false && (
              <div id="button-container">
                <button
                  onClick={join}
                  disabled={matrixUserId.length < 3}
                  id="join-button"
                >
                  Join
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
