const { api_url } = process.env;
import { service } from "./constants";

export const createMippi = (phoneNumber: string, name: string, species: number) => {
    return fetch(`${api_url}/create`, {
        method: "POST",
        body: JSON.stringify({
            phoneNumber,
            name,
            species
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export const getSpecies = () => {
    return fetch(`${api_url}/species`);
}

export const joinMippi = (service: service, identifier: string) => {
    return fetch(`${api_url}/join`, {
        method: "POST",
        body: JSON.stringify({
            service,
            identifier
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
}